import axios from 'axios'

let translationLines = null

const fetchTranslationLineByKey = (key) => {
    if (!translationLines) {
        return null
    }

    return key in translationLines ? translationLines[key] : null
}

export default {
    async loadTranslationLines () {
        const apiUrl = '/api/frontend-translation-lines';

        return axios.get(apiUrl)
            .then(response => {
                translationLines = response.data
                return response.data
            })
            .catch(err => {
                console.error('Error loading translation lines.')
                return Promise.reject(err)
            })
    },

    translate (key, data = null) {
        // E.g.,
        //
        // key = 'greeting'
        //
        // data = [
        //     {
        //         key: 'FIRST_NAME',
        //         value: 'Max'
        //     },
        //     {
        //         key: 'LAST_NAME',
        //         value: 'Mustermann'
        //     }
        // ]

        if (key.trim() === '') {
            return '';
        }

        let translationLine = fetchTranslationLineByKey(key)
        // E.g., translationLine = 'Welcome, ###FIRST_NAME### ###LAST_NAME###'

        // If we can't find the translation line (or the translations couldn't
        // be loaded), we return the key so it's printed out as a fallback
        if (!translationLine) {
            return key
        }

        // If there is dynamic data, we insert it into the translation line
        if (data) {
            for (const item of data) {
                translationLine = translationLine.replaceAll(
                    `###${item.key}###`, item.value
                )
                translationLine = translationLine.replaceAll(
                    `:${item.key}`, item.value
                )
            }
        }

        return translationLine // 'Welcome, Max Mustermann'
    }
}
