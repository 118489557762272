import Vue from 'vue'
import components from '../components'
import Modules from '../modules'
import Xhr from './xhr'
import Dispatcher from './dispatcher'
import Inview from 'vueinview'

import translationHandler from './translation-handler'

export default class App {
  constructor () {
    this.xhr = Xhr
    this.dispatcher = new Dispatcher()
  }

  async init (url) {
    this.url = url;

    // Frontend translation handling

    // We expose the lines globally so they can also be used outside of the
    // translation handler, if needed for some reason

    window.dagobertTranslationLines = null

    try {
        dagobertTranslationLines = await translationHandler.loadTranslationLines()
    } catch {} // We don't need to handle this error further

    window.dagobertTranslate = (key, data = null) => {
        return translationHandler.translate(key, data)
    }

    Vue.mixin({
      methods: {
        translate (key, data = null) {
          return window.dagobertTranslate(key, data)
        }
      }
    })

    Vue.use(Inview)

    this.vue = new Vue({
      el: '#app',
      url,
      components
    })

    this.inview = new Modules.InView();

    this.inview.observe('img[data-src]', (img) => {
      img.src = img.dataset.src;
    });

    this.inview.observe('div[data-bcgimage]', (el) => {
      el.style = "background-image: url(" + el.dataset.bcgimage + ")";
    });

    this.inview.observe('main', () => {
        const images = document.querySelectorAll(
            'img[data-src-on-content-visible'
        );

        for (const image of images)  {
            image.src = image.dataset.srcOnContentVisible;
        }
    });

  }
}
